
.slideshow-container {
  margin-top: 5.5%;
  position: relative;
  width: 100%;
  max-height: 700px;
  overflow: hidden;
  overflow: hidden;
  @media (max-width: 885px) {
    max-height: 200px;
    margin-top: 80px;
}
}

img {
  width: 100%;
  height: auto;
  object-fit: center;
  background-color: rgba(0, 0, 0, 0.3);
  @media (max-width:515px) {
    max-height: 400px;
}
}
.button-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  top: 40%;
  justify-content: space-between;
  transform: translateX(-50%);
  display: flex;
  width: 100%;
}

button {
  display: none;
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  width: auto;
  height: 50px;
  border-radius: 50px;
  svg{
    color: rgba($color: #404040, $alpha: 0.5);
    width: 50px;
    height: 50px;
  }
}

button:hover {
  svg{
    color: rgba($color: #404040, $alpha: 0.8);
  }
}

button:focus {
  outline: none;
}
.slideshow-container:hover{
  button{
    display: flex;
  }
}