.ContactInfo-container{
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background-image:linear-gradient(0deg,transparent 0,rgba(0,0,0,.741) 110%,#000), linear-gradient(180deg,transparent 0,rgba(0,0,0,.741) 110%,#000),  url("./Media/image0.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 885px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
        gap: 100px;
        margin-top: 84px;
}
.Blurry{
    width: 100%;
    height: auto;
    display: flex;
    backdrop-filter: blur(2px);
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    padding: 10% 20%;
    @media (max-width: 885px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
        gap: 100px;
}
}
}
.Contact-Information{
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #1E1F1F ;
    padding: 5%;
    box-shadow: 
        10px 10px 10px #000, 
        inset 0 0 10px #000;
  border-radius: 20px;
    span{
        font-family: 'REM', sans-serif;
        font-size: xx-large;
        color: white;
        font-weight: bold;
    }
    .a-text{
        text-decoration: none;
        color: white;
        font-family: 'REM', sans-serif;
        font-size: large;
    }
    @media (max-width: 885px) {
       align-items: center;
       justify-content: center;
       margin-top: 50px;
       span{
        font-size: x-large;
       }
       a{
        font-size: medium;
       }
}
}
.Social-icon{
    display: flex;
    align-items: center;
    gap: 20px;
    svg{
        width: 30px;
        height: 30px;
    }
}
.svg1{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    color: #3b5998;
}.svg2{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    color: #25D366;
}.svg3{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 35px;
    height: 35px;
color: #C13584;
}
.Googlemapslocation{
    width: 500px;
    height: 400px;
    
    @media (max-width: 1080px) {
        width: 300px;
    height: 200px;
    margin-bottom: 10%;
}
@media (max-width: 885px) {
    width: 350px;
height: 300px;
}
} 
.red-line1{
    width: 100%;
    height: 5px;
    background-color: red;
}
.svg1:hover{
 background-color: #3b5998;;
 svg{
    color: white;
 }
}
.svg2:hover{
    background-color: #25D366;
    svg{
       color: white;
    }
   }
   .svg3:hover{
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    svg{
        color: white;
    }
   }