*{
    margin: 0;
}
body{
    background-color: rgb(30,31,31);
}
.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 20PX;
    background-color: rgb(30,31,31);
    color: white;
    overflow: hidden;
    box-shadow: 0px 1px 10px black;
    .svg{
        display: none;
    }
    .Menu-Button{
        display: none;
        @media (max-width: 885px) {
                display: flex;
                width: 30px;
                height: 30px;
        }
    }
    a{
        color: white;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        gap: 5px;
        text-decoration: none;
        font-family: 'REM', sans-serif;
    }
    .NavBar-Links{
        display: flex;
        align-items: center;
        
        padding: 10px;
        @media (max-width: 885px) {
            display: none;
            .Menu-Button{
                display: flex;
            }
        }
    }
    .Navbar-Links-Border-Button1, .Navbar-Links-Border-Button2, .Navbar-Links-Border-Button3, .Navbar-Links-Border-Button4, .Navbar-Links-Border-Button5, .Navbar-Links-Border-Button6{
        display: flex;
        width: 100%;
        background-color: transparent;
        height: 2px;
    }
}.NavBar-Home-Button:hover, .NavBar-Services-Button:hover, .NavBar-Testimonals-Button:hover, .NavBar-About-Button:hover, .NavBar-Store-Button:hover, .NavBar-Contact-Button:hover{
    .Navbar-Links-Border-Button1, .Navbar-Links-Border-Button2, .Navbar-Links-Border-Button3, .Navbar-Links-Border-Button4, .Navbar-Links-Border-Button5, .Navbar-Links-Border-Button6{
        display: flex;
        width: 100%;
        background-color: white;
        height: 2px;
        animation: expandWidth 0.5s forwards;
    }
}
@keyframes expandWidth {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  .NavBar-Home-Button:active{
    .Navbar-Links-Border-Button1{
        display: flex;
        width: 100%;
        background-color: white;
        height: 2px;
    }
  }
  .Logo-Image{
    background-color: transparent;
    width: 250px;
    height: 50px;
    opacity: 0;
    @media (max-width: 885px) {
       width: 200px;
       height: 40px;
    }
  }