body{
    height: auto;
}
.Red-line{
    width: 100%;
    height: 5px;
    background-color: red;
}
.tuning{
    height: auto;
}