.Social-Container{
    width: 100%;
    height: 550px;
    background-image:linear-gradient(180deg,transparent 0,rgba(0,0,0,.741) 100%,#000), url('../Social/Media/Background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    @media (max-width: 885px) {
        display: flex;
        flex-direction: column;
        background-size: cover;
        background-attachment: scroll;
        height: 700px;
}
}
.left-scial-container{
    width: 30%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    height: 100%;
    z-index: 1;
    margin: 5%;
    @media (max-width: 885px) {
        margin: 5%;
        width: 90%;
}

}.right-scial-container{
    display: flex;
    flex-direction: column;
    
    gap: 20px;
    h1{
        color: white;
        font-size: 2.5rem;
        font-family: 'REM', sans-serif;
        @media (max-width: 885px) {
            font-size: 1.2rem;
            text-align: center;
            margin: 5%;
    }
    }
    @media (max-width: 885px) {
       display: flex;
       align-items: center;
       justify-content: center;
}
}
.red-line{
    width: 100px;
    height: 3px;
    background-color: red;
    margin-bottom: 5%;
}
.left-scial-container{
    display: flex;
        align-items: center;
        justify-content: center;
    iframe{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
        width: 340px;
    }
}