.Ourwork-container{
    padding: 1%;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    gap: 40px;
    overflow: hidden;
}
.Car-Logo{
    width: 7%;
    height: 7%;
    background-color: transparent !important;
    @media (max-width: 885px) {
        width: 30%;
        height: 30%;
       }
}.Car-Logo1{
    width: 7%;
    height: 7%;
    background-color: transparent;
    @media (max-width: 885px) {
     width: 30%;
     height: 30%;
    }
}
.Ourwork-Bottom-Container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 885px) {
        display: flex;
        gap: 100px;
        justify-content: space-around;
        flex-direction: row;
    }
}
.Row1-Logo-Car, .Row2-Logo-Car{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8%;
    flex-wrap: wrap;
    margin-bottom: 2%;
    @media (max-width: 885px) {
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-end;
    }
}.Row2-Logo-Car{
    @media (max-width: 885px) {
        flex-direction: column;
        align-items: flex-start;
    }
}
.Ourwork-Upper-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 40px;
    padding: 5%;
    h1{
        color: white;
        font-size: 2.5rem;
        font-family: 'REM', sans-serif;
        @media (max-width: 885px) {
         font-size:1.2rem;
        }
    }
}