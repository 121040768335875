.Footer-Container{
    height: 300px;
    background-image:linear-gradient(180deg,transparent 0,rgba(0,0,0,.741) 100%,#000), url('./Background/Audia7.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    font-family: 'REM', sans-serif;
    padding: 2%;
    gap: 5%;
    @media (max-width: 945px) {
        display: flex;
        flex-direction: column;
        height: 400px;
        background-position: center;
        background-attachment:scroll;
        background-image:linear-gradient(180deg,transparent 0,rgba(0,0,0,.741) 50%,#000), url('./Background/Audia7.jpg');
        padding-bottom: 50px;
}
}
.Footer-Logo{
    width: 30%;
    height: 100%;
    color: grey;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10%;
    font-size: 1rem;
    @media (max-width: 1109px) {
        width: 100%;
        align-items: center;
        justify-content: center;
}
    @media (max-width: 885px) {
        font-size: .8rem;
}
    span{
        margin-left: 3%;
        @media (max-width: 1109px) {
            font-size: .8rem;
    }
    @media (max-width: 945px) {
        font-size: 1rem;
    }
        
    }
    img{
        background-color: transparent;
        width: 220px;
        height: 50px;
    }

}
.Footer-Contact{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4%;
    gap: 3%;
    @media (max-width: 1109px) {
        width: 100%;
        align-items: center;
        justify-content: center;
}
    span{
        font-size: 1.1rem;
        color: grey;
        @media (max-width: 1109px) {
            font-size: .8rem;
    }
    @media (max-width: 945px) {
        font-size: 1rem;
    }
    }
    a{
        position: relative;
        display: inline-block;
        font-size: 1.1em;
        color: grey;
        overflow: hidden;
    }
    a{
        background: linear-gradient(to right, white, white 50%, grey 50%);
    }
    a{
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 100%;
        background-position: 100%;
    }
    a {
        /* Same as before */
        transition: background-position 275ms ease;
      }
      a:hover {
        background-position: 0 100%;
      
        @media (max-width: 1109px) {
            font-size: .8rem;
    }
    @media (max-width: 945px) {
        font-size: 1rem;
    }
    }
}
.Footer-Menu{
    padding-top: 4%;
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5%;
    font-size: 1rem;
    color: grey;
    a{
        position: relative;
        display: inline-block;
        font-size: 1.1em;
        color: grey;
        overflow: hidden;
    }
    a{
        background: linear-gradient(to right, white, white 50%, grey 50%);
    }
    a{
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 100%;
        background-position: 100%;
    }
    a {
        /* Same as before */
        transition: background-position 275ms ease;
      }
      a:hover {
        background-position: 0 100%;
      }
        @media (max-width: 945px) {
            display: none;
    }
    
}
.Footer-Connect{
    padding-top: 4%;
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10%;
    color: grey;
    @media (max-width: 1109px) {
        width: 100%;
        align-items: center;
        justify-content: center;
}
    @media (max-width: 1109px) {
        font-size: .8rem;
}
@media (max-width: 945px) {
    font-size: 1rem;
}
    .Footer-Icon{
        display: flex;
        gap: 5%;
        svg{
            width: 30px;
            height: 30px;
            color: grey;
        }
        a{
        position: relative;
        display: inline-block;
        font-size: 1.1em;
        color: grey;
        overflow: hidden;
    }
    a{
        background: linear-gradient(to right, white, white 50%, grey 50%);
    }
    a{
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 100%;
        background-position: 100%;
    }
    a {
        /* Same as before */
        transition: background-position 275ms ease;
      }
      a:hover {
        background-position: 0 100%;
      }
    }
}
.svg1:hover{
    color: #3b5998;
}.svg2:hover{
    color: #25D366;
}.svg3:hover{
color: #C13584;
}