.Side-Drawer{
    position: fixed;
    left: 20%;
    right: 20%;
    top: 2%;
    bottom: 50;
    padding: 20PX;
    z-index: 5000;
}
.Top-Menu-Bar{
  position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    left: -50;
    svg{
        width: 25px;
        height: 25px;
    }
}
.Bottom-Menu-bar{
    padding-top: 20%;
    gap: 50px;
    display: flex;
    flex-direction: column;
    font-size: x-large;
    align-items: center;
    width: 100%;
}
@keyframes moveLeft {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}