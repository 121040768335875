.Eevent-Container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0% 10%;
    background-color:#282829 ;
    @media (max-width: 885px) {
        display: flex;
        flex-direction: column;
    }
    gap: 20px;
@media (max-width: 995px) {
    padding: 3% 5%;
}
overflow: hidden!important;
}
.Specifications-Card{
display: flex;
flex-direction: column;
align-items: center;
color: white;
padding: 4%;
gap: 20px;
.Icon-Card{
    svg{
        width: 30px;
        height: 30px;
        color: #D10606;
    }
}
.Title-Card{
    color: white;
    font-family: 'REM', sans-serif;
    font-size: large;
    font-weight: bold;
}
.tittletext-Card{
    display: flex;
    text-align: center;
    font-family: 'REM', sans-serif;
    font-weight: normal;
    color: gray;
}
}